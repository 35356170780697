import React, { useState, useEffect } from "react";
import { Eligilibility } from "../partials/Eligilibility";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";

import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
  toBigInt,
} from "ethers";
import { loginUser, registerUser } from "../../services/user";
import { bnbChainId, bnbRpcUrl, projectId } from "../../constent";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
export const Register = () => {
  const {authenticated, login, setWallet_address}=useAuth()
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const params = useParams();

  useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (address && isConnected) {
      setWallet_address(address);
      localStorage.setItem("wallet_address", address);
    }
  }, [address, isConnected]);

  const mainnet = [
    {
      chainId: bnbChainId,
      name: "Bnb Mainnet",
      currency: "BNB",
      explorerUrl: "https://etherscan.io/",

      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Hawk Protocol",
    description: "Hawk Protocol",
    url: "https://hawkprotocol.live/",
    icons: ["https://hawkprotocol.live/html/images/crudo_Logo.svg"],
  };

  const model=createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  useEffect(() => {
    model.open()
  }, [])
  

  const [showModal, setShowModal] = useState(false);
  const [reffralCode, setReffralCode] = useState(
    "0x2329d5880b782527c7F432706AAD563fF0146969"
  );
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const { walletAddress } = params;
  const navigate = useNavigate();

  useEffect(() => {
    if (walletAddress) {
      setReffralCode(walletAddress);
      localStorage.setItem("reffralCode", walletAddress);
    }
  }, []);

  const registerHandler = async () => {
    if(!address){
      model.open()
      return false
    }
    let sponsor_id = localStorage.getItem("reffralCode");

    if (sponsor_id) {
      sponsor_id = sponsor_id;
    } else {
      sponsor_id = reffralCode;
    }

    let data = { sponsor_id, wallet_address: address };
    const resp = await registerUser(data);
    
    if (resp.status) {
      toast.dismiss();
      toast.success(resp.message);
      const token = resp.token;
    localStorage.setItem("jwtToken", token);
     login()
     setTimeout(() => {
       navigate("/dashboard",{replace:true});
     }, 1000);
    } else {
      toast.error(resp.message);
    }
  };

  const loginUserHandler = async () => {
    if(!address){
      model.open()
      return false
    }
    let data = { wallet_address: address };
    const resp = await loginUser(data);
    if (resp.status) {
      toast.dismiss();
      toast.success(resp.message);
      const token = resp.token;
      localStorage.setItem("jwtToken", token);
      login()
      setTimeout(() => {
        navigate("/dashboard",{replace:true});
      }, 1000);
     
    } else {
      toast.error(resp.message);
    }
  };

  return (
    <>
      <div className>
        <div id="particles-js"></div>
        <main className="s-home s-home--static">
          <div className="home-content dash-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <div className="navi">
                    <a href="/">
                      <img src="/assets/img/logo.png" className="logo-fix" />
                    </a>
                    {/* <a
                      href="javascript:void(0);"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalThree"
                    ></a> */}
                    <button
                      //   variant="primary"
                      onClick={handleShow}
                      className="btn btn-primary"
                    >
                      Eligibility Criteria
                    </button>

                    <Eligilibility show={showModal} handleClose={handleClose} />
                  </div>
                  <div className="dash-content-middle">
                    <div className="wallet-register">
                      <h4>Referral Code:</h4>
                      {/* <div className="copy-add">
                        <p>
                          {walletAddress
                            ? walletAddress
                            : "0x2329d5880b782527c7F432706AAD563fF0146969"}
                        </p>
                      </div> */}
                      <div className="copy-add sponsor-input">
                        <input
                          type="text"
                          onChange={(e)=>setReffralCode(e.target.value)}
                          value={reffralCode}
                          className="input-field"
                        />
                      </div>

                    
                        <a
                          className="btn btn-primary"
                          onClick={registerHandler}
                        >
                          Register now
                        </a>
                      

                      <a
                        className="btn btn-primary ms-3"
                        onClick={loginUserHandler}
                      >
                        Login now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
