import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import { getTaskData } from "../../services/task";
import { addUserTask, getUserTask } from "../../services/userTask";
import Header from "../widgets/Header";
import SideBar from "../widgets/SideBar";

function Task() {
  // [
  //   { type: "facebook", url: "", icon: "fab fa-facebook-f" },
  //   { type: "instagram", url: "", icon: "fab fa-instagram" },
  //   { type: "twitter", url: "", icon: "fab fa-twitter" },
  //   { type: "linkendin", url: "", icon: "fab fa-linkedin" },
  // ]
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const [taskId, setTaskId] = useState("");
  const [record, setRecord] = useState([]);
  const [inputFields, setInputFields] = useState([]);
  const [userTask, setUserTask] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!authenticated) {
      navigate(`/register`);
    } else {
    }
    getData();
  }, []);

  useEffect(() => {
    if (taskId) {
      getUserTaskfun();
    }
  }, [taskId]);

  const getUserTaskfun = async () => {
    const sendData = {
      task_id: taskId,
    };
    const result = await getUserTask(sendData);
    if (result.status) {
      setUserTask(result.data);
    } else {
      setUserTask([]);
    }
  };

  const getData = async () => {
    const result = await getTaskData();
    if (result.status) {
      setRecord(result.data);
    } else {
      setRecord([]);
    }
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedInputFields = [...inputFields];
    updatedInputFields[index] = { ...updatedInputFields[index], [name]: value };
    setInputFields(updatedInputFields);
  };

  const handleTask = (e) => {
    setTaskId(e.target.value);
    if (e.target.value) {
      const filter = record?.filter((list) => {
        return list.id == e.target.value;
      });
      setDescription(filter[0]?.description);
      let arr = [];
      if (filter[0].is_linkedin == 1) {
        arr.push({
          type: "linkendin",
          url: "",
          points: filter[0].points,
          icon: "fab fa-linkedin",
        });
      }
      if (filter[0].is_facebook == 1) {
        arr.push({
          type: "facebook",
          url: "",
          points: filter[0].points,
          icon: "fab fa-facebook",
        });
      }
      if (filter[0].is_instagram == 1) {
        arr.push({
          type: "instagram",
          url: "",
          points: filter[0].points,
          icon: "fab fa-instagram",
        });
      }
      if (filter[0].is_twitter == 1) {
        arr.push({
          type: "twitter",
          url: "",
          points: filter[0].points,
          icon: "fab fa-twitter",
        });
      }
      if (filter[0].is_discord == 1) {
        arr.push({
          type: "discord",
          url: "",
          points: filter[0].points,
          icon: "fab fa-discord",
        });
      }
      if (filter[0].is_telegram == 1) {
        arr.push({
          type: "telegram",
          url: "",
          points: filter[0].points,
          icon: "fab fa-telegram",
        });
      }
      setInputFields(arr);
    } else {
      setDescription("");
      setInputFields();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filter = inputFields.filter((list) => {
      return list.url == "";
    });

    if (taskId == "") {
      toast.dismiss();
      toast.error("Please select the task");
      return;
    }
    if (filter.length == 4) {
      toast.dismiss();
      toast.error("Please enter atleast one field");
      return;
    }
    const urlRegexpatter =
      /https?:\/\/(www\.)?[a-zA-Z0-9-._~:/?#@!$&'()*+,;=%]+\.[a-zA-Z]{2,}([a-zA-Z0-9-._~:/?#@!$&'()*+,;=%]*)?/;
    for (const data of inputFields) {
      if (data?.url) {
        if (!urlRegexpatter.test(data?.url)) {
          toast.dismiss();
          toast.error("Invalid url");
          return;
        }
      }
    }

    const sendData = {
      task_id: taskId,
      input_fields: JSON.stringify(inputFields),
    };
    const result = await addUserTask(sendData);
    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };

  return (
    <>
      <div class="">
        <main className="s-home s-home--static">
          {/* <div class="overlay"></div> */}
          <div className="home-content dash-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <Header />
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 das-box">
                  <div className="row">
                    <div className="col-md-3">
                      <SideBar />
                    </div>
                    <div className="col-md-9">
                      <div className="dash-right pt-4 pb-4">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <h2 className="task-heading">
                              Join and Retweet the HAWK Protocol Social Media
                              Handles and Earn Exciting Rewards
                            </h2>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-md-6">
                                <label className="form-label">
                                  Select Task
                                </label>
                                <select
                                  className="form-control"
                                  value={taskId}
                                  onChange={handleTask}
                                >
                                  <option value="">Select Task</option>
                                  {record?.map((list) => {
                                    return (
                                      <option value={list.id}>
                                        {list.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6"></div>
                              {description && (
                                <div className="col-12  mt-3">
                                  <h5>Description</h5>
                                  {/* <p>{description}</p> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: description,
                                    }}
                                  />
                                </div>
                              )}
                              {inputFields?.map((list, i) => {
                                const filterData = userTask?.filter((el) => {
                                  return el.type == list.type;
                                });
                                return (
                                  <div className="col-md-6 col-sm-6">
                                    <div class="activity-coin-wrapper">
                                      <p>
                                        {list.type} {list.points} coins
                                      </p>
                                      <div class="activity-coin">
                                        <a href="javascript:void(0);">
                                          <i
                                            class={list.icon}
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="url"
                                          value={
                                            filterData.length > 0
                                              ? filterData[0].url
                                              : list.url
                                          }
                                          onChange={(e) => {
                                            handleChange(i, e);
                                          }}
                                          disabled={
                                            filterData.length > 0 ? true : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              {userTask?.length >= 4 ? (
                                ""
                              ) : inputFields.length > 0 ? (
                                <button
                                  class="btn btn-primary"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Task;
